<template>
    <section  class="container-fluid">
        <div class="product_section top_selling">
            <div class="" v-for="product in home.homeViewCategoryProducts" :key="product.id">
                <br>
            <div v-if="product.products.length > 0" class="slider-titile">
                <h4 class="text-center slider-title-text">{{ product.name }}</h4>
            </div>
            <SingleProductForHome v-if="product.products.length > 0" :products="product.products" />
        </div>
        </div>
    </section>
</template>

<script>
    import { mapGetters } from "vuex";
    import SingleProductForHome from "@/components/Home/SingleProductItem.vue";
    export default {
        components: {
            SingleProductForHome
        },
        computed: {
            ...mapGetters(["home"]),
        },
    };
</script>

<style></style>
