<template>
  <section class="container-fluid">
    <div class="product_section sale_now">
      <div class="slider-titile">
        <h4 class="text-center slider-title-text">SALES NOW</h4>
      </div>
      <SaleNowProduct :products="saleNow" />
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
//import SaleNowProduct from "@/components/Home/SingleProductItem.vue"
import SaleNowProduct from "../Home/SingleProductItem"
export default {
  components: {
    SaleNowProduct
  },
  computed: {
    ...mapGetters(["saleNow"]),
  },
};
</script>

<style scoped></style>
