<template>
  <!-- skeleton -->
  <div v-if="!isSliderLoad" class="skeleton container-fluid">
    <div class="home-slider"></div>
    <div class="row">
      <div class="col-lg-4" v-for="index in 3" :key="index">
        <div class="promo-ads"></div>
      </div>
    </div>

    <div class="row skeleton-products">
      <div class="product_section sale_now">
        <div class="slider-title">
          <h4 class="text-center slider-title-text">SALES NOW</h4>
        </div>
      </div>
      <div class="col-md-12 col-cs-6" v-for="index in 6" :key="index">
        <div class="skeleton-card">
          <div class="skeleton-img"></div>
          <div class="skeleton-btn"></div>
          <div class="skeleton-title-one"></div>
          <div class="skeleton-title-two"></div>
          <div class="skeleton-price-one"></div>
          <div class="skeleton-price-two"></div>
          <div class="skeleton-add-btn"></div>
        </div>
      </div>
    </div>

    <div class="skeleton-products">
      <div class="product_section sale_now">
        <div class="slider-title">
          <h4 class="text-center slider-title-text">CURRENTLY TRENDING</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-2 col-cs-6" v-for="index in 6" :key="index">
          <div class="skeleton-card">
            <div class="skeleton-img"></div>
            <div class="skeleton-btn"></div>
            <div class="skeleton-title-one"></div>
            <div class="skeleton-title-two"></div>
            <div class="skeleton-price-one"></div>
            <div class="skeleton-price-two"></div>
            <div class="skeleton-add-btn"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- home data -->
  <div v-else class="home">
    <div v-if="isSliderLoad" class="main_slider d-none d-md-block">
      <Slider />
    </div>
    <div v-if="isSliderLoad" class="d-none d-md-block">
      <OfferArea />
    </div>
    <div v-if="isSliderLoad" class="section-desktop-view">
      <SalesNow />
      <HomeDynamicSection />
      <LatestProduct />
      <TopSellingProduct />
    </div>
    <div v-if="isSliderLoad" class="section-mobile-view">
      <HomeDynamicSection />
      <LatestProduct />
      <SalesNow />
      <TopSellingProduct />
    </div>
  </div>
</template>

<script>
import OfferArea from "../components/Home/OfferArea";
import SalesNow from "../components/Home/SalesNow";
import HomeDynamicSection from "../components/Home/HomeDynamicSection";
import LatestProduct from "../components/Home/LatestProduct";
import TopSellingProduct from "../components/Home/TopSellingProduct";
import Slider from "./header/Slider";
import { mapGetters } from "vuex";

export default {
  name: 'Home',
  data() {
    return {
      isSliderLoad: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.isSliderLoad = true;
    }, 500);
  },
  computed: {
    ...mapGetters(["loading", "home"]),
  },
  components: {
    OfferArea,
    SalesNow,
    HomeDynamicSection,
    LatestProduct,
    TopSellingProduct,
    Slider,
  },
};
</script>

<style>
/* Adjust CSS if necessary */
</style>
